import React from 'react';
import styled from 'styled-components'
import { Container, Row, Column } from './grid';
import { Image } from './image';
import { H1 } from './typography';
import Background from '../media/cover.png';
import LandingLogo from '../svg/logo-eco-premiere-transportation.svg'
import { device } from './mediaquery';
import PhoneIcon from '../icons/phone';
import Envelope from '../icons/envelope';
import Address from '../icons/address';

const TopContainer = styled(Container)`
    background-image: url(${Background});
    background-position:center center;
    background-repeat:no-repeat;
    z-index:0;
    @media ${device.mobileL}{
        height:calc( 100vh - 120px );
    } 
`;

const StyledContainer = styled(Container)`
    @media ${device.mobileL}{
        flex-direction:${props => props.theme.direction.column};
        height:120px;
        justify-content:${props => props.theme.justifyContent.center};
    } 
`;

const StyledRow = styled(Row)`
    position:relative;
    z-index:10000;
`;

const MaskLayer = styled.div`
    background-color:rgba(255,255,255,0.75);	
    z-index:9999;
    width:100%;
    height:calc( 100vh - 75px );
    position:absolute;

    @media ${device.mobileL}{
        height:calc( 100vh - 120px );
    }
`;

const Text = styled(H1).attrs({
    as: 'h2'
})`
    font-size: ${props => props.theme.fontSizes.extraMedium};
    color:${props => props.theme.colors.secondary};

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 896px) 
    and (orientation : landscape) {
        text-align:${props => props.theme.textAlign.center};
    }

    @media ${device.mobileL}{
        text-align:${props => props.theme.textAlign.center};
    }

    @media ${device.mobileM}{
        font-size: ${props => props.theme.fontSizes.small};
    }
`;

const Logo = styled(Image)`
    width:210px;
    height:auto;

    @media ${device.mobileM}{
        width:160px;
    }
`;

const Info = styled.div`
    font-size: ${props => props.theme.fontSizes.paragraph};
    color:${props => props.theme.colors.primary};
    display:flex;
    flex-direction:${props => props.theme.direction.row};
    align-items:${props => props.theme.alignItems.center};

    svg {
        margin-right:8px;
    }
    @media ${device.mobileL}{
        padding-top:3px;
        padding-bottom:3px;
    }
`;

function Landing() {
    return (
        <>
            <TopContainer justifyContent="center" height="calc( 100vh - 75px )">
                <MaskLayer />
                <StyledRow >
                    <Column justifyContent="center" alignItems="center" direction="column">
                        <h1>
                            <Logo src={LandingLogo} alt="ECO Premiere Transportation" />
                        </h1>
                        <Text>
                            Website is under construction
                    </Text>
                    </Column>
                </StyledRow>
            </TopContainer>
            <StyledContainer justifyContent="spaceAround" height="75px" alignItems="center">
                <Info>
                    <PhoneIcon />
                    +297 597-7675
            </Info>
                <Info>
                    <Envelope />
                    info@eptaruba.com
            </Info>
                <Info>
                    <Address />
                Italiestraat 16, Oranjestad, Aruba
            </Info>
            </StyledContainer>
        </>
    )
}
export default Landing;