import React from 'react';
import Theme from '../src/theme/';
import GlobalStyles from '../src/theme/global-styles';
import Landing from '../src/components/landing';

function App() {
    return (
        <Theme>
            <GlobalStyles />
            <Landing />
        </Theme>
    );
}
export default App;