import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    min-width: 18px;
    width: 18px;
    max-width: 18px;
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    color: #269858;
    user-select: none;
    padding-right:5px;
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;
    color: #269858;
`;

const AddressIcon = ({ className, iconColor='defaultPrimary' }) => (
    <Container className={className} >
        <SVG fontColor={iconColor} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" className="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></SVG>
    </Container>
);

export default AddressIcon;