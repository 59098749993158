import styled from 'styled-components';

export const H1 = styled.h1`
    font-size:${props => props.theme.fontSizes.extraLarge};
    font-weight:${props => props.theme.fontWeight.bold};
    line-height:100%;
    text-transform:uppercase;
    color:${props => props.theme.colors[props.color]};
    letter-spacing:0;
    text-align:${props => props.theme.textAlign.left};
`;

export const H2 = styled(H1).attrs({
    as: "h2"
})`
    font-size:${props => props.theme.fontSizes.large};
`;

export const H3 = styled(H1).attrs({
    as: "h3"
})`
    font-size:${props => props.theme.fontSizes.medium};
`;

export const H4 = styled(H1).attrs({
    as: "h4"
})`
    font-size:${props => props.theme.fontSizes.extraSmall};
    font-weight:${props => props.theme.fontWeight.regular};
`;

export const Paragraph = styled.p`
    line-height:100%;
    color:${props => props.theme.colors.secondary};
    letter-spacing:0;
    text-align:${props => props.theme.textAlign.left};
    font-size:16px;
    padding-bottom: ${props => props.spacing ? props => props.theme.spacing[props.spacing] : ''};
`;

export const Text = styled.summary`
    font-size:${props => props.theme.fontSizes.extraSmall};
    color:${props => props.theme.colors.secondary};
    text-align:${props => props.theme.textAlign.left};
    padding-top:${props => props.theme.spacing.tripleInset};
    padding-left:${props => props.theme.spacing.doubleInset};
`;
