import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 18px;
    height: 18px;
    color: ${props => props.theme.colors.defaultSecondary};
    user-select: none;
    padding-right:5px;
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;
    color:#269858;
`;

const PhoneIcon = ({ className, iconColor='defaultPrimary' }) => (
    <Container className={className} >
        <SVG aria-hidden="true" fontColor={iconColor} focusable="false" data-prefix="fas" data-icon="phone" className="svg-inline--fa fa-phone fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"/></SVG>
    </Container>
);

export default PhoneIcon;