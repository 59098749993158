import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
    colors: {
        defaultPrimary: "#191919", // black-fox
        defaultSecondary: "#ffffff", // white
        primary: "##00212C",
        secondary: "#002c3a",
    },
    fonts: {
        firstFont: "Open Sans"
    },
    fontWeight: {
        thin: "100",
        extraLight: "200",
        light: "300",
        regular: "400",
        medium: "500",
        semiBold: "600",
        bold: "700",
    },
    fontSizes: {
        superSmall: "14px",
        extraSmall: "16px",
        small: "24px",
        extraMedium: "30px",
        superMedium: "36px",
        medium: "50px",
        large: "60px",
        extraLarge: "90px",
        paragraph: "14px"
    },
    spacing: {
        inset: "15px",
        doubleInset: "30px",
        tripleInset: "60px",
        quarterlyInset: "90px",
        megaInset: "120px",
    },
    textAlign: {
        left: "left",
        right: "right",
        center: "center",
        justify: "justify"
    },
    direction: {
        column: "column",
        row: "row",
        columnReverse: "column-reverse",
        rowReverse: "row-reverse"
    },
    alignItems: {
        start: "flex-start",
        center: "center",
        end: "flex-end",
        spaceBetween: "space-between",
        strech: "strech"
    },
    justifyContent: {
        start: "flex-start",
        center: "center",
        end: "flex-end",
        spaceBetween: "space-between",
        spaceAround: "space-around",
        spaceEvenly: "space-evenly"
    },
    position: {
        relative: "relative",
        fixed: "fixed",
        sticky: "sticky",
        absolute: "absolute"
    },
    transition: {
        linear: "all 0.2s linear",
    },
}

function Theme({ children }) {
    return (
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
    )
}
export default Theme;